import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "./http.service";
import { Router } from "@angular/router";
import * as signalR from "@microsoft/signalr";
import { AuthService } from "src/app/core/auth/authentication.service";
import { MessageDto } from "../model/messages/message";
import { MessageReply } from "../model/messages/message-reply";
import { MessageRoomDto } from "../model/messages/message-room.dto";
import { MessageRoomCompactDTO } from "../model/messages/message-room-compact.dto";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(
    private _httpService: ApiService,
    public router: Router,
    public authService: AuthService
  ) {}

  private messageHub = environment.hub.message;
  private messageRoutes = environment.api.messages

  hubConnection?: signalR.HubConnection;
  messageHubConnectionId: string | any;
    msSubject = new Subject<any>();
  msObservable(): Observable<any> {
    return this.msSubject.asObservable();
  }

  startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.messageHub, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.closeMessageHubConnectionListen();
        this.msSubject.next({ type: "MessageHubStarted" });
      })
      .catch((err) => console.log("Error while starting connection: " + err));
    console.log(`HubConnection.state: ${this.hubConnection?.state}`);
    setTimeout(() => {
      console.log(`HubConnection.state: ${this.hubConnection?.state}`);
    }, 2000);
  };


  closeMessageHubConnectionListen(): void {
    this.hubConnection?.on("closeMessageuHubConnectionResponse", () => {
      location.reload();
      console.log(`HubConnection.state: ${this.hubConnection?.state}`);
    });
  }

  logOutInvoke(): void {
    console.log(`disconnecting MessageHub`);
    this.hubConnection
      ?.invoke("CloseConnection")
      .catch((err) => console.error(err));
  }

  addAndRemoveMessageRoomLike(like: any): Observable<any> {
    const url = this.messageRoutes.base;
    return this._httpService.Post<any>(url, like);
  }


  getRoomsForUser(userId: number){
    return this._httpService.Get<MessageRoomCompactDTO[]>(this.messageRoutes.room.getCurrentRooms,{params: {id: userId}});
  }

  getMessageRoomForUser(userId: number){
    return this._httpService.Get<MessageRoomDto>(this.messageRoutes.room.user,{params: {id: userId}});
  }

  getAllMessagesInRoom(id: number): Observable<MessageDto[]> {
    return this._httpService.Get<MessageDto[]>(this.messageRoutes.room.getMessages, { params: { id } });
  }
  replyToMessage(messageReply: MessageReply): Observable<any> {
    return this._httpService.Post(this.messageRoutes.replyToMessage, messageReply);
  }

  getSharedRoom(participantId: number): Observable<MessageRoomDto[]> {
    return this._httpService.Get<MessageRoomDto[]>(this.messageRoutes.room.findShared, {params: { participantId } });
  }
}
